.main:before {
  content: ' ';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: url(./assets/images/background/stars.webp) repeat 100%;
}
.main {
  // XL
  position: relative;
  overflow: hidden;
  color: white;

  // XL
  @screen xl {
    background: radial-gradient(ellipse at top, rgb(10, 24, 49) 0%, #000000 100%);
  }
  background: linear-gradient(180deg, rgb(10, 24, 49), #000000);
}

/********** ANIMATION **********/
.fade-in {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
