@keyframes steam {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.glow {
  z-index: 1;
  position: absolute;
  background: linear-gradient(0deg, #000, #272727);
}
.glow:before,
.glow:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(35deg, #b118ac, #4c4cff, #e69500, #b118ac, #4c4cff, #e69500);
  background-size: 400%;
  width: calc(100%);
  height: calc(100%);
  z-index: -1;
  animation: steam 60s linear infinite;
}
.glow:after {
  filter: blur(40px);
}
